import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Page from 'commons/page'
import Block from 'components/block'
import Button from 'components/button'
import { ButtonWrapper } from 'components/layout'
import { PageTitle, Text } from 'components/typography'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { UserRole } from 'schema/user'

const AccessDenied = () => {
  const { claims } = useAuthProvider()

  const topPage = useMemo(() => {
    return claims?.role === UserRole.AGENT
      ? ROUTING_PATH.C0201
      : ROUTING_PATH.B0201
  }, [claims?.role])

  return (
    <Page title="403 ACCESS DENIED" type="guest">
      <PageTitle>403 ACCESS DENIED</PageTitle>
      <Block>
        <Text align={'center'}>このページへのアクセス権がありません。</Text>
      </Block>
      <ButtonWrapper>
        <Button as={Link} to={topPage}>
          トップページ
        </Button>
      </ButtonWrapper>
    </Page>
  )
}

export default AccessDenied
